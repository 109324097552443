<template>
  <div class="menu-bottom">
    <div class="layout-header-true">
      <div class="flex alcenter space" style="height: 100%; overflow: hidden">
        <div class="flex alcenter" style="overflow: hidden auto">
          <img
            v-if="siteInfo != null"
            :src="siteInfo.logo_white_admin_manage"
            class="layout-header-logo"
          />
          <div class="menu-box">
            <div class="flex alcenter menu-list">
              <div
                class="menu-list-item"
                v-for="(item, index) in menus"
                :key="index"
                @click="menuAct(index)"
                :class="{ active: getSelectKey.indexOf(item.key) > -1 }"
              >
                <i class="iconfont ft14" :class="item.icon" />
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="more">
          <a-dropdown placement="bottomCenter">
            <span>
              {{ admin.name }}
              <a-icon type="down" />
            </span>
            <a-menu slot="overlay">
              <a-menu-item>
                <a class="menu-act" href="javascript:;" @click="showPwd = true">
                  <i class="iconfont ft14 iconedit"></i>
                  <span class="ml10">修改密码</span>
                </a>
              </a-menu-item>
              <a-menu-item>
                <a class="menu-act" href="javascript:;" @click="loginOut()">
                  <i class="iconfont ft14 iconsubnav_shop04"></i>
                  <span class="ml10">退出登录</span>
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div> -->
      </div>
    </div>
    <a-modal v-model="showPwd" title="设置密码" on-ok="handleOk">
      <template slot="footer">
        <a-button key="back" @click="handlePwdCancel"> 取消 </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="pwdloading"
          @click="handlePwdOk"
        >
          确认
        </a-button>
      </template>
      <a-form-model
        :model="pwd"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="输入密码">
          <a-input type="password" v-model="pwd.pwd" />
        </a-form-model-item>
        <a-form-model-item label="再次确认">
          <a-input type="password" v-model="pwd.repwd" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import script from './script';
export default {...script}
</script>

<style lang="less">
.menu-bottom {
  .layout-header-true {
    box-shadow: 3px 5px 26px 7px rgba(0, 0, 0, 0.18);
    position: fixed;
    /* top: 0; */
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    height: 60px;
    background: #fff;
    z-index: 999;
    color: #333;
    border-radius: 16px;
    overflow: hidden;
  }

  .layout-header-logo {
    height: 24px;
  }
  .space {
    width: calc(100vw - 20px);
    justify-content: center;
  }
  .layout-content {
    // min-height: calc(100vh - 60px);
  }
  .menu-box {
    overflow-x: auto;
  }
  .menu-list-item {
    flex: none;
    width: 92px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    cursor: pointer;
  }

  .menu-list-item.active {
    color: #8b79e8;
  }
  .more {
    width: 40px;
    text-align: center;
    flex: none;
  }
}
</style>
