<template>
  <div>
    <a-spin :spinning="false">
      <div class="layout-content pd10">
        <menuLeft :admin="admin" v-if="!isH5" />
        <div class="router-view">
          <router-view></router-view>
        </div>
      </div>
      <menuBottom :admin="admin" v-if="isH5" />
    </a-spin>
  </div>
</template>

<script>
import menuBottom from "./components/menuBottom.vue";
import menuLeft from "./components/menuLeft.vue";
export default {
  components: { menuBottom, menuLeft },
  data() {
    return {
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      showPwd: false,
      pwd: {
        pwd: "",
        repwd: "",
      },
      pwdloading: false,
      admin: {
        name: "",
      },
      isH5: false,
    };
  },
  created() {
    let _this = this;
    this.loaddata();
    this.isH5 = window.innerWidth <= 992;
    window.addEventListener("resize", () => {
      _this.isH5 = window.innerWidth <= 992;
    });
  },
  // computed: {
  //   isH5() {
  //     return window.innerWidth <= 768;
  //   },
  // },
  methods: {
    loaddata() {
      if (this.loading == true) return;
      this.loading = true;
      this.$http
        .api("platform/admin/layout")
        .then((res) => {
          this.admin = res.admin;
        })
        .catch((res) => {
          console.log(res);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.layout-content {
  // min-height: 100vh;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  .router-view {
    box-shadow: 3px 5px 26px 7px rgba(0, 0, 0, 0.18);
    flex: 1;
    background: #fff;
    border-radius: 16px;
    padding: 0 10px;
    overflow-y: auto;
    box-sizing: border-box;
    max-height: calc(100vh - 20px);
  }
}
</style>
