export default {
  props: {
    admin: {
      type: Object,
      default: () => ({
        name: "",
      }),
    },
  },
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      showPwd: false,
      pwd: {
        pwd: "",
        repwd: "",
      },
      pwdloading: false,
      siteInfo: null,
      menus: [
        {
          name: "首页",
          icon: "icontop_nav01",
          key: "home",
          link: "/home",
        },
        {
          name: "用户",
          icon: "iconzhanghao1",
          key: "account",
          link: "/account",
        },
        {
          name: "商铺",
          icon: "icontop_nav02",
          key: "shop",
          link: "/shop",
        },
        {
          name: "模版",
          icon: "iconmoban",
          key: "template",
          link: "/template",
        },
        {
          name: "免认证",
          icon: "iconsubnav_shop01",
          key: "free",
          link: "/shop/free",
        },
        {
          name: "设置",
          icon: "icontop_nav05",
          key: "setting",
          link: "/setting",
        },
      ],
    };
  },
  created() {
    this.getSiteInfo();
  },
  methods: {
    getSiteInfo() {
      this.$http
        .api("platform/siteInfo")
        .then((res) => {
          this.siteInfo = res.site;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    menuAct(index) {
      let data = this.menus[index];
      if (data.key != this.getSelectKey) {
        this.$router.push(data.link);
      }
    },
    handlePwdCancel() {
      this.showPwd = false;
    },
    handlePwdOk() {
      if (this.pwdloading == true) return;
      this.pwdloading = true;
      this.$http
        .api("platform/admin/editPwd", {
          pwd: this.pwd.pwd,
          repwd: this.pwd.repwd,
        })
        .then((res) => {
          this.pwdloading = false;
          this.$message.success("密码设置成功");
          this.showPwd = false;
        })
        .catch((res) => {
          this.pwdloading = false;
        });
    },
    loginOut() {
      localStorage.removeItem("admin-token");
      this.$router.push("/");
    },
  },
};
